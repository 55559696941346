import React from "react";
import { Routes, Route } from "react-router-dom";
import { setLoginDetails } from "../../Actions/Actions";
import { connect } from "react-redux";
import Home from "../Home/Home";
import Footer from "../Shared/Footer/Footer";
import Results from "../Results/Results";

function Routing() {
  return (
    <>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
