import React from "react";
import { useState, CSSProperties } from "react";
import { Oval } from "react-loader-spinner";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function HomeContentLoader() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#370d59");

  const spinnerStyle = {
    fontSize: "40px",
    marginBottom: "10px",
  };

  const loadingMessageStyle = {
    textAlign: "center",
  };

  const spinnerContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(255,255,255)",

    color: "black",
    fontSize: "16px",
    zIndex: "9999",
  };

  return (
    <div style={spinnerContainerStyle}>
      <div className="tp-preloader-logo">
        <div className="tp-preloader-circle">
          <Oval
            height={150}
            width={150}
            color="#8a583c"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#E3E0DE"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
          <br />
          <p
            className="tp-preloader-subtitle"
            style={{ alignContent: "center" }}
          >
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeContentLoader;
