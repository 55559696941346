import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import { isMobile } from "react-device-detect";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { serverLink } from "../../Resources/Url";

const color = "#1a1a1a";

const Home = (props) => {
  const containerStyle = {
    display: "flex",
    backgroundColor: "white",
  };
  const itemStyle = {
    flex: 1,
    padding: "5px",
    // backgroundColor: "lightgray",
    backgroundColor: "white",
    border: "0.5px solid #0b1834",
    cursor: "pointer",
  };

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formattedTime = currentTime.toLocaleTimeString();

  const stationStorage = localStorage.getItem("currentStationId");
  const [stationId, setStationId] = useState(
    stationStorage ? stationStorage : 1
  );

  const [isPlaying, setIsPlaying] = useState(false);
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  const [firstItem, setFirstItem] = useState(
    stationId ? stationId : pathSegments[0] ? pathSegments[0] : 1
  );

  const [oap] = useState(
    typeof props.HomePageData.oap !== "undefined" ? props.HomePageData.oap : []
  );

  const [stations] = useState(
    typeof props.HomePageData.stations !== "undefined"
      ? props.HomePageData.stations
      : []
  );

  const [programs] = useState(
    typeof props.HomePageData.programs !== "undefined"
      ? props.HomePageData.programs
      : []
  );

  const filteredStations = stations.filter(
    (r) => r.StationId === Number(firstItem)
  );

  const filteredPrograms = programs.filter(
    (r) => r.StationId === Number(firstItem)
  );

  const [currentProgram, setCurrentProgram] = useState(null);
  useEffect(() => {
    if (filteredPrograms && filteredPrograms.length > 0) {
      const checkProgramMatch = () => {
        const currentTime = new Date();

        const matchingProgram = filteredPrograms.find((program) => {
          const startTime = new Date();
          const [startHour, startMinute] = program.StartTime.split(":");
          startTime.setHours(startHour, startMinute, 0, 0);

          const endTime = new Date();
          const [endHour, endMinute] = program.EndTime.split(":");
          endTime.setHours(endHour, endMinute, 0, 0);

          return currentTime >= startTime && currentTime <= endTime;
        });

        if (matchingProgram && Object.keys(matchingProgram).length > 0) {
          setCurrentProgram(matchingProgram);
        }
        if (matchingProgram && Object.keys(matchingProgram).length === 0) {
          const matchingProgram = programs.find(
            (r) => r.ProgramId === Number(1)
          );
          setCurrentProgram(matchingProgram);
        }
      };

      checkProgramMatch(); // Initial check on component mount

      const interval = setInterval(checkProgramMatch, 60000); // Check every minute

      return () => {
        clearInterval(interval);
      };
    }
    if (filteredPrograms && Object.keys(filteredPrograms).length === 0) {
      const matchingProgram = programs.find((r) => r.ProgramId === Number(1));
      console.log("Outside matching not Found", matchingProgram);
      setCurrentProgram(matchingProgram);
    }
  }, []);

  const startPlayer = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    setTimeout(async () => {
      startPlayer();
    }, 1000);
  });

  if (!isPlaying) {
    return <HomeContentLoader />;
  }

  const changeStation = (stationId) => {
    localStorage.removeItem("currentStationId");
    localStorage.setItem("currentStationId", JSON.stringify(stationId));
    setFirstItem(stationId);
    window.location.reload();
  };

  const redirectToFacebook = (facebook) => {
    window.location.href = `${facebook}`;
  };

  return (
    <>
      <main className="site-body">
        <section
          className="player-section section-bg"
          style={{
            backgroundColor: filteredStations[0].Color
              ? filteredStations[0].Color
              : color,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-audio-player">
                  <div className="single-audio-thumb">
                    <img
                      src={`${serverLink}public/uploads/stations/${filteredStations[0].Logo}`}
                      alt="image"
                    />
                  </div>
                  <div className="single-audio-content">
                    {currentProgram &&
                    Object.keys(currentProgram).length > 0 ? (
                      <>
                        {" "}
                        <h5 className="title">{currentProgram.ProgramName}</h5>
                        <p className="audio-time">
                          {
                            oap
                              .filter(
                                (i) => i.OAPId === Number(currentProgram.OAPId)
                              )
                              .map((x) => (
                                <>
                                  <p>
                                    {
                                      oap
                                        .filter(
                                          (i) =>
                                            i.OAPId ===
                                            Number(currentProgram.OAPId)
                                        )
                                        .map((x) => (
                                          <>
                                            <div className="image">
                                              <img
                                                src={`${x.Passport}`}
                                                alt="image"
                                                width="80px"
                                                height="80px"
                                              />
                                            </div>
                                          </>
                                        ))[0]
                                    }{" "}
                                  </p>
                                  {x.FullName}
                                </>
                              ))[0]
                          }{" "}
                          <br />
                          {/*START*/}
                          <div>
                            {currentProgram.StartTime} -{" "}
                            {currentProgram.EndTime}{" "}
                            <strong>
                              <span
                                className="live-status"
                                style={{ backgroundColor: "red" }}
                              >
                                Live
                              </span>
                            </strong>
                          </div>
                          {/*END*/}
                        </p>
                      </>
                    ) : (
                      <>
                        {" "}
                        <h4 className="title">Relaxing Hours</h4>
                        <p className="audio-time">
                          {formattedTime}
                          <strong>
                            <span
                              className="live-status"
                              style={{ backgroundColor: "red" }}
                            >
                              Live
                            </span>
                          </strong>
                        </p>
                      </>
                    )}

                    {isPlaying && (
                      <audio
                        controls
                        src={filteredStations[0].CloudUrl}
                        autoPlay
                      ></audio>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <a
              href={filteredStations[0].WebsiteUrl}
              target="_blank"
              style={{
                color: "black",
                textDecoration: "none",
                fontSize: "16px",
                width: isMobile && "100%",
                marginTop: "10px",
                textAlign: "center",
                fontWeight: "bold",
                backgroundColor: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s, transform 0.2s",
              }}
              rel="noopener noreferrer"
            >
              Go to {filteredStations[0].StationName}'s website
            </a>
          </div>
        </section>

        <section className="pb-120">
          <div className="container">
            <div className="tab-content show-tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="day1-tab-pane"
                role="tabpanel"
                aria-labelledby="day1-tab"
                tabIndex="0"
              >
                <div style={containerStyle}>
                  {stations.length > 0 &&
                    stations.map((item, index) => {
                      return (
                        <>
                          <div
                            style={itemStyle}
                            onClick={() => changeStation(item.StationId)}
                          >
                            {item.StationId === Number(firstItem) ? (
                              <>
                                <img
                                  src={`${serverLink}public/uploads/stations/${item.Playing}`}
                                  alt="image"
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={`${serverLink}public/uploads/stations/${item.Tune}`}
                                  alt="image"
                                />
                              </>
                            )}
                          </div>
                        </>
                      );
                    })}
                </div>
                {/*FACEBOOK LINK*/}
                <div style={containerStyle}>
                  {stations.length > 0 &&
                    stations.map((item, index) => {
                      return (
                        <>
                          <div
                            style={itemStyle}
                            onClick={() =>
                              redirectToFacebook(item.FacebookLink)
                            }
                          >
                            <img
                              src={`${serverLink}public/uploads/stations/${item.Facebook}`}
                              alt="image"
                            />
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
